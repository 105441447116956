import municipalitiesJSON from '@/lib/regions/municipalities.json';
import provincesJSON from '@/lib/regions/provinces.json';
import { GroupedOptionProp, OptionProp } from '@/lib/optionProps';
import { slugify } from '../utils';

const validLocales = ['fi', 'sv'] as const;
export type RegionLocale = typeof validLocales[number];
export type RegionType = 'municipality' | 'province';
export interface Region {
  id: number;
  provinceId?: number;
  name: {
    [key in RegionLocale]: string;
  };
  type: RegionType;
}
export interface Municipality extends Region {
  provinceId: number;
  type: 'municipality';
}
export interface Province extends Region {
  provinceId: never;
  type: 'province';
}

export type GroupedRegions = Record<
  string,
  { province: string; municipalities: string[] }
>;

export const municipalities: Region[] = municipalitiesJSON.value.map((x) => ({
  id: x.Kunta_Id,
  provinceId: x.Maakunta_Id,
  name: {
    fi: x.Nimi,
    sv: x.NimiRuo,
  },
  type: 'municipality',
}));

export const provinces: Region[] = provincesJSON.value.map((x) => ({
  id: x.Maakunta_Id,
  name: {
    fi: x.Nimi,
    sv: x.NimiRuo,
  },
  type: 'province',
}));

export function isMunicipality(region?: Region): region is Municipality {
  return (
    typeof region === 'object' &&
    'provinceId' in region &&
    typeof region.provinceId === 'number'
  );
}

export const isValidRegionLocale = (
  locale: string | undefined,
): locale is RegionLocale => {
  return (validLocales as ReadonlyArray<string | undefined>).includes(locale);
};

export const getRegionValue = ({
  name,
}: Partial<Region> & Pick<Region, 'name'>) => name.fi; // use untranslated name as value

export const getRegionName = (
  region: Partial<Region> & Pick<Region, 'name'>,
  locale = 'fi',
) => {
  const validLocale = isValidRegionLocale(locale) ? locale : 'fi';
  return region.name[validLocale];
};

export function getRegionByName(nameInFi?: string | null): Region | undefined {
  if (!nameInFi) {
    return undefined;
  }
  const regions = municipalities.concat(provinces);
  return regions.find((l) => l.name.fi === nameInFi);
}

export function getRegionById(
  type: RegionType,
  id?: number | null,
): Region | undefined {
  if (!id) {
    return undefined;
  }
  if (type === 'municipality') {
    return municipalities.find((m) => m.id === id);
  }
  return provinces.find((p) => p.id === id);
}

export function getLocalizedRegionName(
  nameInFi?: null,
  locale?: string,
): undefined;
export function getLocalizedRegionName(
  nameInFi: string,
  locale?: string,
): string;
export function getLocalizedRegionName(
  nameInFi?: string | null,
  locale?: string,
): string | undefined;
export function getLocalizedRegionName(
  nameInFi?: string | null,
  locale = 'fi',
) {
  const finalLocale: RegionLocale = isValidRegionLocale(locale) ? locale : 'fi';
  if (!nameInFi) {
    return undefined;
  }
  if (finalLocale === 'fi') {
    return nameInFi;
  }
  const regionObj = getRegionByName(nameInFi);

  return regionObj?.name[finalLocale] || nameInFi;
}

export function getLocalizedRegionNames(
  namesInFi?: string[] | null,
  locale?: string,
  unique?: boolean,
) {
  if (!namesInFi) {
    return [];
  }

  let names = namesInFi.filter((n): n is string => !!n);
  if (unique) {
    names = [...new Set(namesInFi)];
  }

  return names.map((name) => getLocalizedRegionName(name, locale));
}

export const matchesLocalizedRegionNames = (
  namesInFi?: string[] | null,
  searchLocales: (string | undefined)[] = ['fi'],
  searchString = '',
) => {
  if (!namesInFi || !namesInFi.length) {
    return false;
  }

  const finalLocales = searchLocales.map((l) =>
    isValidRegionLocale(l) ? l : 'fi',
  );

  return finalLocales.some((lang) =>
    getLocalizedRegionNames(namesInFi, lang).some(
      (area) => area && new RegExp(searchString, 'i').test(area),
    ),
  );
};

export const getMunicipalityOptionProps = (
  locale?: string,
): OptionProp<string>[] => {
  const finalLocale: RegionLocale = isValidRegionLocale(locale) ? locale : 'fi';
  return municipalities.map((x) => ({
    value: x.name.fi,
    label: x.name[finalLocale] || x.name.fi,
    type: x.type,
  }));
};

export const getProvinceOptionProps = (
  locale?: string,
): OptionProp<string>[] => {
  const finalLocale: RegionLocale = isValidRegionLocale(locale) ? locale : 'fi';
  return provinces.map((x) => ({
    value: x.name.fi,
    label: x.name[finalLocale] || x.name.fi,
    type: x.type,
  }));
};

export const getMatchingRegionOptionsProps = ({
  value,
  type,
  locale,
}: {
  value: string;
  type: 'municipality' | 'province';
  locale: string;
}) => {
  if (type === 'municipality') {
    return getMunicipalityOptionProps(locale).find((m) => value === m.value);
  }
  return getProvinceOptionProps(locale).find((p) => value === p.value);
};

export const getRegionOptionProps = (locale?: string): OptionProp<string>[] =>
  getProvinceOptionProps(locale).concat(getMunicipalityOptionProps(locale));

export const getRegionOptionPropsGrouped = (
  locale?: string,
): GroupedOptionProp<string>[] => [
  { label: 'Maakunnat', options: getProvinceOptionProps(locale) },
  { label: 'Kunnat', options: getMunicipalityOptionProps(locale) },
];

export const getMunicipalitiesGroupedByProvince = (
  namesInFi: Array<string | undefined>,
  locale = 'fi',
): GroupedRegions => {
  const groupedRegions = namesInFi.reduce<Record<string, string[]>>(
    (all, curr) => {
      if (!curr) {
        return all;
      }

      const municipality = getRegionByName(curr);
      if (!isMunicipality(municipality)) {
        // dismiss all values that don't resolve to a municipality
        return all;
      }

      const localizedName = getLocalizedRegionName(curr, locale);
      if (!localizedName) {
        return all;
      }

      const key = municipality.provinceId.toString();

      return {
        ...all,
        [key]: [...(all[key] || []), localizedName],
      };
    },
    {},
  );

  return Object.entries(groupedRegions).reduce(
    (acc, [provinceId, municipalityList]) => {
      const province = provinces.find((p) => p.id === parseInt(provinceId, 10));
      if (!province?.name.fi) {
        return acc;
      }

      const key = slugify(province.name.fi);
      const provinceName = getLocalizedRegionName(province.name.fi, locale);

      if (!key) {
        return acc;
      }

      return {
        ...acc,
        [key]: { province: provinceName, municipalities: municipalityList },
      };
    },
    {},
  );
};
